.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 1;
}

.Highlight__part {
  position: absolute;
  /* background: transparent; */
  transition: background 0.3s;
  /* background: rgb(224, 189, 189); */
  text-decoration-line: line-through;
  -webkit-text-decoration-line: line-through;
  text-decoration-color: red;
  -webkit-text-decoration-color: red;
  text-decoration: line-through red;
  -webkit-text-decoration: line-through red;
}

.Highlight__popup {
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  max-width: 300px;
  max-height: 100px;
  overflow-y: scroll;
}
